import {
  PAYMENT_SOURCE_MANUAL,
  PAYMENT_STATUS_COMPLETED,
} from "@/constants/payments";

export default {
  type: "reseller-payments",
  relationshipNames: ["invoice", "reseller"],
  invoice: {
    type: "reseller-invoices",
    id: null,
  },
  amount: 0,
  status: PAYMENT_STATUS_COMPLETED,
  source: PAYMENT_SOURCE_MANUAL,
};
