<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper" v-if="!invoice">
      <base-input
        :label="`${$t('COMMON.INVOICE')} (*)`"
        :placeholder="$t('COMMON.INVOICE')"
      >
        <reseller-invoice-selector
          :resellerInvoice="resellerPayment.invoice.id"
          :filterStatus="INVOICE_STATUS_VALIDATED"
          :filterable="true"
          :showAll="false"
          @resellerInvoiceChanged="
            (resellerInvoiceId, resellerInvoice) => {
              resellerPayment.invoice.id = resellerInvoiceId;
              resellerPayment.amount = resellerInvoice.total_remaining_payment;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.invoice" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.SOURCE')} (*)`"
        :placeholder="$t('COMMON.SOURCE')"
      >
        <el-select
          name="Source"
          v-model.lazy="resellerPayment.source"
          placeholder="Source"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
          <el-option
            v-for="(label, val) in sourcesOption"
            :key="val"
            :value="val"
            :label="label"
          ></el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.source" />
    </div>

    <div class="form-wrapper">
      <base-input :label="$t('COMMON.STATUS')">
        <el-select name="status" v-model="resellerPayment.status">
          <el-option
            v-for="(label, val) in statusesOption"
            v-bind:key="val"
            :value="val"
            :label="label"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.status" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.AMOUNT')} (*)`"
        placeholder="amount"
        v-model="resellerPayment.amount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
        @change="
          () => {
            onFormChanged();
          }
        "
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.amount" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor
          v-model="resellerPayment.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div
      class="form-group-wrapper"
      v-if="resellerPayment.source != PAYMENT_SOURCE_MANUAL"
    >
      <h2 class="col-12 mt-0">{{ $t("RESELLER_PAYMENTS.SOURCE_DETAILS") }}</h2>
      <div class="form-group-wrapper-item full">
        <base-input
          :label="$t('RESELLER_PAYMENTS.TRANSACTION_ID')"
          v-model="resellerPayment.transaction_id"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.transaction_id" />
      </div>

      <div class="form-group-wrapper-item full">
        <base-input :label="$t('RESELLER_PAYMENTS.TRANSACTION_DATA')">
          <textarea
            class="form-control"
            rows="6"
            v-model="resellerPayment.transaction_data"
            @change="
              () => {
                onFormChanged();
              }
            "
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.transaction_data" />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          resellerPayment.id
            ? $t("RESELLER_PAYMENTS.EDIT_RESELLER_PAYMENT")
            : $t("RESELLER_PAYMENTS.ADD_RESELLER_PAYMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import {
  paymentSourcesOption,
  paymentStatusesOption,
  PAYMENT_SOURCE_MANUAL,
} from "@/constants/payments";
import { INVOICE_STATUS_VALIDATED } from "@/constants/invoices";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ResellerInvoiceSelector from "@/components/ResellerInvoiceSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerInvoiceSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["resellerPaymentData", "formErrors", "loading"],

  data() {
    return {
      resellerPayment: { ...this.resellerPaymentData },
      statusesOption: paymentStatusesOption,
      sourcesOption: paymentSourcesOption,
      PAYMENT_SOURCE_MANUAL: PAYMENT_SOURCE_MANUAL,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
      invoice: null,
    };
  },

  created() {
    this.setInvoice();
  },

  methods: {
    async setInvoice() {
      if (this.$route.query.invoice_id) {
        this.resellerPayment.invoice.id = this.$route.query.invoice_id;
      }
      if (this.$route.query.amount) {
        this.resellerPayment.amount = this.$route.query.amount;
      }
    },

    async handleSubmit() {
      const resellerPaymentData = cloneDeep(this.resellerPayment);
      if (!resellerPaymentData.reseller.id) {
        delete resellerPaymentData.reseller;
      }
      this.$emit("resellerPaymentSubmitted", resellerPaymentData);
    },

    async resellerInvoiceChanged(invoiceId, resellerInvoice) {},

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    resellerPaymentData(resellerPaymentData) {
      if (resellerPaymentData) {
        this.resellerPayment = {
          ...this.resellerPayment,
          ...cloneDeep(resellerPaymentData),
        };
      }
    },
  },
};
</script>
